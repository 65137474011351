import React from "react"
import Img from "gatsby-image"

const ConatctCard = ({ block }) => (
  <section className="bg-white mb-8 flex mb-4">
    <div className="mb-4 w-1/4">
      <Img fluid={block.thumbnail.childImageSharp.fluid} alt={block.name}/>
    </div>

    <div className="w-3/4 pl-6">
      <h5 className="text-base mb-2 hyphens">{block.name}</h5>
      <p className="md:text-sm">{block.bio}</p>
      {!!block.email ? (<React.Fragment><a className="link text-orange hover:text-orange-dark transition" href={'mailto:' + block.email}>{block.email}</a><br/></React.Fragment>) : ''}
      <a className="link text-orange hover:text-orange-dark transition" href={'tel:' + block.phone}>{block.phone}</a>
    </div>
  </section>
)

export default ConatctCard